import { ResourceProps } from 'react-admin';
import { JobCreate } from 'resources/job/JobCreate';
import { JobEdit } from 'resources/job/JobEdit';
import { JobList } from 'resources/job/JobList';
import { JobShow } from 'resources/job/JobShow';

export const job: ResourceProps = {
  name: 'job',
  list: JobList,
  show: JobShow,
  edit: JobEdit,
  create: JobCreate,
};

import { Auth } from '@aws-amplify/auth';
import { createHashHistory } from 'history';
import buildHasuraProvider from 'ra-data-hasura';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { useEffect, useState } from 'react';
import {
  Admin,
  AuthProvider,
  CustomRoutes,
  DataProvider,
  Resource,
  UserIdentity,
} from 'react-admin';
import { Route } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Layout } from 'components/Layout';
import {
  employer,
  employer_user,
  job,
  job_question,
  job_additional_category,
  location,
  user,
} from 'resources';
import { customBuildFields } from 'utils';

import { ReportDashboard, JobBuilder } from 'pages';
import DrawerNavigation, { DrawerProvider } from 'components/DrawerNavigation';
import EmployerReport from './EmployerReport';

export default function AdminRouter(): JSX.Element {
  const client = useApolloClient();
  const [dataProvider, setDataProvider] = useState<DataProvider>();

  useEffect(() => {
    if (!client) return;
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider(
        { client },
        { buildFields: customBuildFields }
      );

      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, [client]);

  if (!(dataProvider && client)) return <p>Loading...</p>;

  return (
    <DrawerProvider>
      <Admin
        title="Trade Jobs NZ"
        layout={Layout}
        {...{
          dataProvider,
          i18nProvider,
          authProvider,
          history,
        }}
      >
        <Resource {...job} />
        <Resource {...location} />
        <Resource {...employer} />
        <Resource {...user} />
        <Resource {...employer_user} />
        <Resource {...job_question} />
        <Resource {...job_additional_category} />
        <Resource name="enum_work_type" />
        <CustomRoutes>
          <Route path="dashboard" element={<ReportDashboard />} />
          <Route path="ai" element={<JobBuilder />} />
          <Route path="report/employer" element={<EmployerReport />} />
          <Route path="report/employer/:id" element={<EmployerReport />} />
        </CustomRoutes>
      </Admin>
      <DrawerNavigation />
    </DrawerProvider>
  );
}

// [React-admin - Including the Admin in Another App](https://marmelab.com/react-admin/CustomApp.html#using-an-existing-redux-provider)
const history = createHashHistory();

// https://marmelab.com/react-admin/Translation.html#specific-case-in-confirm-messages-and-empty-page
// https://github.com/marmelab/react-admin/tree/master/packages/ra-language-english
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
  // https://github.com/marmelab/react-admin/issues/5727#issuecomment-778251452
  onMissingKey: (key: string) => key,
});

const authProvider: AuthProvider = {
  checkAuth: () => Promise.resolve(),
  checkError: () => Promise.resolve(),
  getIdentity: () => Promise.resolve({} as UserIdentity),
  getPermissions: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: async () => {
    await Auth.signOut();
    window.location.reload();
  },
};

import { ResourceProps, ShowGuesser } from 'react-admin';
import { JobQuestionCreate } from 'resources/job_question/JobQuestionCreate';
import { JobQuestionEdit } from 'resources/job_question/JobQuestionEdit';
export { JobQuestionFormToolbar } from 'resources/job_question/JobQuestionCreate';

export const job_question: ResourceProps = {
  name: 'job_question',
  // list: JobQuestionList,
  show: ShowGuesser,
  edit: JobQuestionEdit,
  create: JobQuestionCreate,
};

import { ComponentProps, FC } from 'react';
import { Layout as LayoutDefault, LayoutProps } from 'react-admin';
import { Container } from '@mui/material';
import { useLocation } from 'react-router';
import AppBar from 'components/AppBar';

type LayoutError = ComponentProps<typeof LayoutDefault>['error'];
export type LayoutErrorProps = ComponentProps<NonNullable<LayoutError>>;

export const Layout: FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const headless = location.hash === '#headless';

  return (
    <Container maxWidth="xl">
      {!headless && <AppBar />}
      <div style={{ paddingTop: headless ? '0px' : '64px' }}>{children}</div>
    </Container>
  );
};

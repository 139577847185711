import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-admin';
import {
  AddBusinessOutlined,
  AnalyticsOutlined,
  AutoAwesomeOutlined,
  BusinessOutlined,
  EditLocationOutlined,
  PersonOutlined,
  WorkOutlined,
} from '@mui/icons-material';

const adminItems = [
  {
    text: 'Jobs',
    to: 'job',
    Icon: WorkOutlined,
  },
  {
    text: 'Employers',
    to: 'employer',
    Icon: AddBusinessOutlined,
  },
  {
    text: 'Users',
    to: 'user',
    Icon: PersonOutlined,
  },
  {
    text: 'Employer users',
    to: 'employer_user',
    Icon: PersonOutlined,
  },
  {
    text: 'Locations',
    to: 'location',
    Icon: EditLocationOutlined,
  },
];

const menuItems = [
  {
    text: 'Reports',
    to: 'dashboard',
    Icon: AnalyticsOutlined,
  },
  {
    text: 'Employer Reports',
    to: 'report/employer',
    Icon: BusinessOutlined,
  },
  {
    text: 'Job Builder',
    to: 'ai',
    Icon: AutoAwesomeOutlined,
  },
];

export default function DrawerNavigation() {
  const { open, toggle } = useContext(DrawerContext);

  return (
    <Drawer anchor="left" open={open} onClose={() => toggle(false)}>
      <Box
        sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}
        role="presentation"
        onClick={() => toggle(!open)}
        onKeyDown={() => toggle(!open)}
      >
        <List sx={{ flexGrow: 1 }}>
          {adminItems.map(({ text, to, Icon }) => (
            <ListItem key={`${text}`} disablePadding>
              <Link to={to} as={ListItemButton}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </Link>
            </ListItem>
          ))}
          <Divider />
          {menuItems.map(({ text, to, Icon }) => (
            <ListItem key={`${text}`} disablePadding>
              <Link to={to} as={ListItemButton}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

type DrawerContextType = {
  open: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
};

const DrawerContext = React.createContext<DrawerContextType>({
  open: false,
  toggle: () => false,
});

type DrawerProviderProps = PropsWithChildren<Record<string, unknown>>;

export function DrawerProvider({ children }: DrawerProviderProps): JSX.Element {
  const [open, toggle] = useState<boolean>(false);
  return (
    <DrawerContext.Provider value={{ open, toggle }}>
      {children}
    </DrawerContext.Provider>
  );
}

export function useDrawerContext(): DrawerContextType {
  return useContext(DrawerContext);
}

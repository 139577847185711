import { makeValidateSync } from 'mui-rff';
import * as Yup from 'yup';

export const makeValidateDebug =
  (schema: Yup.AnySchema, debug?: boolean) =>
  (values: Record<string, unknown>): any => {
    // leave it verbosey for debugging purposes
    debug && console.log('values:', values);
    try {
      const val = makeValidateSync(schema as any)(values);
      debug && console.log('val::', val);
      return val;
    } catch (e) {
      console.error({ e });
    }
  };

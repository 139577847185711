const revalidatePath = async (path: string) => {
  const secret = process.env.REACT_APP_REVALIDATE_TOKEN;

  const fetchData = await fetch(
    'https://www.tradejobsnz.co.nz/api/revalidate',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ path, secret }),
    }
  );

  const data = await fetchData.json();

  return data;
};

export { revalidatePath };

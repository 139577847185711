import { Create, CreateProps } from 'react-admin';
import { JobFormBody } from './JobFormBody';

export function JobCreate(props: CreateProps): JSX.Element {
  return (
    <Create {...{ transform, ...props }}>
      <JobFormBody
        initialValues={{
          listing_type: 'standard',
          job_status: 'active',
          show_pay: false,
        }}
      />
    </Create>
  );
}

type JobSimple = {
  title: string;
} & Record<string, unknown>;

const transform = (job: JobSimple): JobSimple => ({
  ...job,
  slug: `${job.title
    .toLowerCase()
    .replaceAll(' ', '-')}-${Date.now()}-${Math.ceil(Math.random() * 1000)}`,
});

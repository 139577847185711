import { ResourceProps } from 'react-admin';
import { EmployerCreate } from 'resources/employer/EmployerCreate';
import { EmployerEdit } from 'resources/employer/EmployerEdit';
import { EmployerList } from 'resources/employer/EmployerList';
import { EmployerShow } from 'resources/employer/EmployerShow';

export const employer: ResourceProps = {
  name: 'employer',
  list: EmployerList,
  show: EmployerShow,
  edit: EmployerEdit,
  create: EmployerCreate,
};

import { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import * as Yup from 'yup';
import { Divider } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { v4 as uuidV4 } from 'uuid';
import { makeChoices, useEnums, makeValidateDebug } from 'utils';

type Props = {
  initialValues?: Record<string, unknown>;
};

export const EmployerFormBody: FC<Props> = ({ initialValues = {} }) => {
  const {
    data: { enum_category, enum_billing, enum_organisation },
  } = useEnums();
  return (
    <SimpleForm
      {...{ validate }}
      defaultValues={initialValues}
      sanitizeEmptyValues={false}
    >
      <h4 style={{ marginBottom: '8px' }}>Required fields</h4>
      <small>These fields are all required to create an organisation</small>
      <TextInput required source="name" fullWidth />
      <TextInput required defaultValue={uuidV4()} source="slug" />
      <SelectInput
        required
        defaultValue="default"
        source="organisation_type"
        label="Type"
        choices={makeChoices(enum_organisation)}
      />
      <SelectInput
        required
        defaultValue="standard"
        source="billing_type"
        choices={makeChoices(enum_billing)}
      />
      <SelectInput
        required
        source="category"
        choices={makeChoices(enum_category)}
      />
      <ReferenceInput
        source="location_id"
        reference="location"
        perPage={Infinity}
        sort={{ field: 'label', order: 'ASC' }}
        label="Location (type to search)"
        fullWidth
        defaultValue={null}
      >
        <AutocompleteInput
          defaultValue={null}
          filterToQuery={label => ({ label })}
          fullWidth
          optionText="label"
        />
      </ReferenceInput>
      <Divider style={{ width: '100%' }} />
      <h4>Optional fields</h4>
      <small>
        These fields are all recommended for companies with an active directory
        page
      </small>
      <TextInput source="main_contact" fullWidth />
      <BooleanInput defaultValue={false} source="page_active" />
      <RichTextInput source="description" fullWidth />
      <NumberInput defaultValue={null} source="employees" />
      <NumberInput defaultValue={null} source="founded" />
      <TextInput source="website" fullWidth />
      <TextInput source="linkedin" fullWidth />
      <TextInput source="facebook" fullWidth />
      <TextInput source="instagram" fullWidth />
      <TextInput source="logo" fullWidth />
      <TextInput source="cover_photo" fullWidth />
      <TextInput source="testimonial" fullWidth multiline />
      <TextInput source="testimonial_from" fullWidth />
      <Divider style={{ width: '100%' }} />
      <h4>Parent organisation fields</h4>
      <small>
        This field is used for recruitment or multi-organisation users. The
        parent organisation <b>MUST</b> be of type <b>Recruiter</b> or{' '}
        <b>Conglomerate</b>. The list is filtered to only show companies of
        those types.
      </small>
      <ReferenceInput
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ organisation_type: ['recruiter', 'conglomerate'] }}
        source="parent_id"
        reference="employer"
        label="Parent"
      >
        <SelectInput emptyText="None" optionText="name" fullWidth />
      </ReferenceInput>
    </SimpleForm>
  );
};

const schema: Yup.AnySchema = Yup.object({
  name: Yup.string().required(),
  billing_type: Yup.string().required(),
  category: Yup.string().nullable(),
  cover_photo: Yup.string().nullable(),
  description: Yup.string(),
  employees: Yup.number().nullable(),
  facebook: Yup.string().nullable(),
  founded: Yup.number().nullable(),
  instagram: Yup.string().nullable(),
  linkedin: Yup.string().nullable(),
  location_id: Yup.string(),
  main_contact: Yup.string().nullable(),
  logo: Yup.string(),
  organisation_type: Yup.string().required(),
  page_active: Yup.boolean(),
  parent_id: Yup.string().nullable(),
  testimonial: Yup.string().nullable(),
  testimonial_from: Yup.string().nullable(),
  slug: Yup.string().required(),
  website: Yup.string().nullable(),
});

const validate = makeValidateDebug(schema, true);

import qs from 'querystring';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  DeleteButton,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router';
import { JobQuestionFormBody } from './JobQuestionFormBody';

export const JobQuestionCreate: FC<CreateProps> = (props) => {
  const location = useLocation();
  const job_id = qs.parse(location.search.replace('?', '')).job_id;
  return (
    <Create {...props} record={{ ...props.record, job_id }}>
      <JobQuestionFormBody initialValues={{ job_id, type: 'boolean' }} />
    </Create>
  );
};

export const JobQuestionFormToolbar: FC<ToolbarProps> = (props) => {
  const location = useLocation();
  const job_id = qs.parse(location.search.replace('?', '')).job_id;
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect(`/job/${job_id}/show`);
  };

  return (
    <Toolbar {...props}>
      <SaveButton mutationOptions={{ onSuccess: onSuccess }} />
      <DeleteButton redirect={`/job/${job_id}/show`} />
    </Toolbar>
  );
};

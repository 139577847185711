import { FC } from 'react';
import { List, Datagrid, TextField, ListProps } from 'react-admin';

export const LocationList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="label" />
      <TextField source="region" />
      <TextField source="district" />
      <TextField source="municipality" />
      <TextField source="neighbourhood" />
      {/* <TextField source="country" /> */}
      {/* <TextField source="geometry.type" /> */}
      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
);

import { useState } from 'react';
import { RefreshIconButton } from 'ra-ui-materialui';
import axios from 'axios';

type ButtonRevalidateProps = {
  path: string;
};

export default function ButtonRevalidate({
  path,
}: ButtonRevalidateProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  // Hacky but works
  const isDev = !window.location.origin.startsWith('https://admin.tradejobs');
  async function handleClick() {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.post(
        `https://${
          isDev
            ? 'tradejobs-frontend-git-dev-whileandfor.vercel.app'
            : 'tradejobsnz.co.nz'
        }/api/revalidate`,
        {
          path,
          secret: process.env.REACT_APP_REVALIDATION_SECRET,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  return <RefreshIconButton disabled={loading} onClick={handleClick} />;
}

import { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';

const postFilters = [<TextInput key="search" source="email" alwaysOn />];

export const UserList: FC<ListProps> = (props) => (
  <List {...props} filters={postFilters}>
    <Datagrid rowClick="show">
      <DateField source="created_at" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="type" />
      <ReferenceField
        source="employer_id"
        reference="employer"
        label="Employer"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

import { ResourceProps, ShowGuesser } from 'react-admin';
import { JobAdditionalCategoryCreate } from 'resources/job_additional_category/JobAdditionalCategoryCreate';
import { JobAdditionalCategoryEdit } from 'resources/job_additional_category/JobAdditionalCategoryEdit';

export const job_additional_category: ResourceProps = {
  name: 'job_additional_category',
  show: ShowGuesser,
  edit: JobAdditionalCategoryEdit,
  create: JobAdditionalCategoryCreate,
};

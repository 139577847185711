import { ResourceProps, ShowGuesser } from 'react-admin';
import { LocationList } from 'resources/location/LocationList';

export const location: ResourceProps = {
  name: 'location',
  list: LocationList,
  show: ShowGuesser,
  // edit: EditGuesser,
  // create: EditGuesser,
};

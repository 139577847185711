import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useQuery } from '@apollo/client';

import { useParams, useNavigate } from 'react-router-dom';

import { GET_EMPLOYERS_QUERY, EmployerSelectType } from 'utils/exportXlsx';

export default function EmployerSelect(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading } = useQuery(GET_EMPLOYERS_QUERY);
  return (
    <FormControl fullWidth>
      <InputLabel id="employer-select-label">Employer</InputLabel>
      <Select
        disabled={loading}
        labelId="employer-select-label"
        value={id ?? null}
        label="Employer"
        onChange={(e) => navigate(`/report/employer/${e.target.value}`)}
      >
        {data?.employer.map(({ name, id }: EmployerSelectType) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

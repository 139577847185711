import { FC } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import * as Yup from 'yup';
import { Divider } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { makeChoices, makeValidateDebug, useEnums } from 'utils';

type Props = {
  initialValues?: Record<string, unknown>;
};

export const JobFormBody: FC<Props> = ({ initialValues = {} }) => {
  const {
    data: {
      enum_pay_type,
      enum_category,
      enum_listing,
      enum_work_type,
      enum_job_status,
    },
  } = useEnums();
  const today = new Date();
  const nextMonth = new Date();
  nextMonth.setDate(today.getDate() + 30);

  return (
    <SimpleForm {...{ validate, initialValues }}>
      <h4>Required fields</h4>
      <TextInput required source="title" fullWidth />
      <RichTextInput source="about" fullWidth />
      <ReferenceInput
        required
        source="employer_id"
        reference="employer"
        perPage={Infinity}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="name"
          fullWidth
          filterToQuery={name => ({ name })}
        />
      </ReferenceInput>

      <ReferenceInput
        required
        source="location_id"
        reference="location"
        perPage={Infinity}
        sort={{ field: 'label', order: 'ASC' }}
        label="Location (type to search)"
      >
        <AutocompleteInput
          fullWidth
          filterToQuery={label => ({ label })}
          optionText="label"
        />
      </ReferenceInput>
      <SelectInput
        required
        source="job_category"
        choices={makeChoices(enum_category)}
      />
      <SelectInput
        required
        defaultValue="standard"
        source="listing_type"
        choices={makeChoices(enum_listing)}
      />
      <SelectInput
        required
        source="work_type"
        choices={makeChoices(enum_work_type)}
      />
      <SelectInput
        required
        source="pay_type"
        choices={makeChoices(enum_pay_type)}
      />
      <NumberInput required defaultValue={null} source="pay_low" />
      <NumberInput required defaultValue={null} source="pay_high" />
      <BooleanInput defaultValue={false} source="show_pay" />
      <Divider style={{ width: '100%' }} />
      <h4>To make listing visible</h4>
      <small>
        In order to make a listing live the following must be true, defaults
        values have been set that adhere to these rules
        <ul>
          <li>The list date must be today or before</li>
          <li>The expiry date must be set and be after today</li>
          <li>The job must have a status of active</li>
          <li>The job must NOT have a deleted at date</li>
        </ul>
      </small>
      <SelectInput
        required
        defaultValue="active"
        source="job_status"
        choices={makeChoices(enum_job_status)}
      />
      <DateInput
        required
        defaultValue={today.toDateString()}
        source="list_date"
      />
      <DateInput
        required
        defaultValue={nextMonth.toDateString()}
        source="expiry_date"
      />
      <small>
        Only set the following field if you wish to hide the listing from the
        search list
      </small>
      <DateInput defaultValue={null} source="deleted_at" />
      <Divider style={{ width: '100%' }} />
      <h4>Optional fields</h4>
      <TextInput source="apply_url" fullWidth />
      <TextInput source="tagline" fullWidth />
      <ArrayInput source="highlights" fullWidth>
        <SimpleFormIterator>
          <TextInput source="" fullWidth key={Math.random()} />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="requirements" fullWidth>
        <SimpleFormIterator>
          <TextInput source="" fullWidth key={Math.random()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

const schema: Yup.AnySchema = Yup.object({
  about: Yup.string().required(),
  apply_url: Yup.string().url('must be a valid url').optional().nullable(),
  // created_by_user_id?: Maybe<Scalars['uuid']>;
  // deleted_at?: Maybe<Scalars['timestamptz']>;
  employer_id: Yup.string().required(),
  highlights: Yup.array().of(Yup.string()).nullable(),
  job_category: Yup.string().required(),
  job_status: Yup.string().required(),
  location_id: Yup.string().required(),
  // slug: Yup.string().required(),
  pay_high: Yup.number().min(0).required(),
  pay_low: Yup.number().min(0).required(),
  pay_type: Yup.string().required(),
  requirements: Yup.array().of(Yup.string()).nullable(),
  tagline: Yup.string().nullable(),
  title: Yup.string().required(),
  work_type: Yup.string().required(),
});

const validate = makeValidateDebug(schema, true);

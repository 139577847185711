import { Amplify } from '@aws-amplify/core';
import { FC } from 'react';
import { AuthProvider } from 'components/AuthContext';
import ReactAdmin from 'components/ReactAdmin';
import './App.css';
import awsConfig from './aws-config';

Amplify.configure(awsConfig);

const App: FC = () => (
  <AuthProvider>
    <ReactAdmin />
  </AuthProvider>
);

export default App;

import qs from 'querystring';
import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';
import { useLocation } from 'react-router';

import { JobAdditionalCategoryFormBody } from './JobAdditionalCategoryFormBody';

export const JobAdditionalCategoryCreate: FC<CreateProps> = (props) => {
  const location = useLocation();
  const job_id = qs.parse(location.search.replace('?', '')).job_id;
  return (
    <Create {...props} record={{ job_id }}>
      <JobAdditionalCategoryFormBody initialValues={{ job_id }} />
    </Create>
  );
};

import React, { Suspense, useState } from 'react';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Markdown from 'markdown-to-jsx';
import { AIResult } from 'forms/AIJobPost/prompt';

const MuiMarkdown = React.lazy(() => import('mui-markdown'));
const AIJobPost = React.lazy(() => import('forms/AIJobPost'));

type ItemProps = React.PropsWithChildren<{
  title: string;
}>;

function Item({ title, children }: ItemProps): JSX.Element {
  return (
    <Stack direction="column" spacing={1} justifyContent="flex-start">
      <p>
        <strong>{title}</strong>
      </p>
      <div>{children}</div>
    </Stack>
  );
}

export default function JobBuilder() {
  const [{ response, error }, setRes] = useState<AIResult>({
    ok: true,
    error: null,
    response: null,
  });

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        columns={{ xs: 1, md: 2 }}
        spacing={2}
      >
        <Grid item xs={1}>
          <Suspense>
            <AIJobPost callback={setRes as any} />
          </Suspense>
        </Grid>
        <Grid item xs={1}>
          <Suspense>
            <Paper sx={{ padding: '18px' }}>
              <Stack spacing={2} divider={<Divider flexItem />}>
                {error ? (
                  <>
                    <Item title="Error">{error.reason}</Item>
                    <Item title="Message">
                      <Markdown>{error.message}</Markdown>
                    </Item>
                  </>
                ) : response ? (
                  <>
                    <Item title="Company">{response.company_name}</Item>
                    <Item title="Title">{response.title}</Item>
                    <Item title="Tagline">{response.tagline}</Item>
                    <Item title="Pay range">{`$${response.pay_low} - ${response.pay_high} / ${response.pay_type}`}</Item>
                    <Item title="About">
                      <MuiMarkdown>{response.about}</MuiMarkdown>
                    </Item>
                    <Item title="Requirements">
                      <List>
                        {response?.requirements.map((i) => (
                          <ListItem key={i}>{i}</ListItem>
                        ))}
                      </List>
                    </Item>
                    <Item title="Highlights">
                      <List>
                        {response?.highlights.map((i) => (
                          <ListItem key={i}>{i}</ListItem>
                        ))}
                      </List>
                    </Item>
                  </>
                ) : null}
              </Stack>
            </Paper>
          </Suspense>
        </Grid>
      </Grid>
    </Container>
  );
}

import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Text } from '@tremor/react';
import { Title } from 'ra-ui-materialui';

// Hooks
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

/* eslint-disable import/no-duplicates */
import { add, set } from 'date-fns';
import enNZ from 'date-fns/locale/en-NZ';
import setDefaultOptions from 'date-fns/setDefaultOptions';
/* eslint-enable import/no-duplicates */

import {
  headers,
  createReport,
  EXPORT_QUERY,
  JobType,
  GET_EMPLOYER_NAME,
} from 'utils/exportXlsx';

// Relative imports
import EmployerSelect from './EmployerSelect';

setDefaultOptions({ locale: enNZ });

type DateConfig = {
  start_date: Date;
  end_date: Date;
};

const midnight = {
  hours: 14,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};
const formatDate = (date: Date): string => date.toISOString().split('T')[0];
const DEFAULT_START_DATE = set(new Date(), { ...midnight, date: 1 });
const DEFAULT_END_DATE = add(DEFAULT_START_DATE, { months: 1 });

export default function EmployerReport(): JSX.Element {
  const [l, setLoading] = useState<boolean>(false);
  const [{ start_date, end_date }, setDate] = useState<DateConfig>({
    start_date: DEFAULT_START_DATE,
    end_date: DEFAULT_END_DATE,
  });
  const { id } = useParams();
  const {
    data,
    loading: l2,
    refetch,
  } = useQuery(EXPORT_QUERY, {
    variables: {
      employer_id: id,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    },
    skip: !(start_date && end_date && id),
  });
  const { data: employer } = useQuery(GET_EMPLOYER_NAME, { variables: { id } });

  const loading = l || l2;

  useEffect(() => {
    if (loading || !(start_date && end_date)) return;
    refetch();
  }, [loading, start_date, end_date]);

  return (
    <Container style={{ marginTop: '2rem' }}>
      <Title title={employer?.employer_by_pk?.name ?? ''} />
      <Grid
        style={{ marginBottom: '1em' }}
        container
        justifyContent="space-between"
        gap={2}
        alignItems="end"
      >
        <Grid xs={6}>
          <Text>Reporting Data – {employer?.employer_by_pk?.name ?? ''}</Text>
          <EmployerSelect />
        </Grid>
        <Grid>
          <TextField
            id="date"
            label="Start date"
            type="date"
            onChange={(e) => {
              const nextDate = set(new Date(e.target.value), midnight);
              setDate({
                start_date: nextDate,
                end_date,
              });
            }}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </Grid>
          <Grid>
          <TextField
            id="date"
            label="End date"
            type="date"
            onChange={(e) => {
              const nextDate = set(new Date(e.target.value), midnight);
              setDate({
                start_date,
                end_date: nextDate,
              });
            }}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              color={loading ? 'info' : 'primary'}
              variant="contained"
              disabled={loading}
              onClick={async () => {
                if (!id || loading) return;
                try {
                  setLoading(true);
                  await createReport(
                    id,
                    formatDate(start_date),
                    formatDate(end_date)
                  );
                } catch (error) {
                  console.log(error);
                } finally {
                  setLoading(false);
                }
              }}
            >
              Download report
            </Button>
            {(l || loading) && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="Employer reporting table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.job.map(
              ({
                id,
                title,
                listing_type,
                applications_aggregate,
                job_views,
                list_date,
                expiry_date,
                integration_job,
                event_job_aggregate
              }: JobType): JSX.Element => (
                <TableRow key={id}>
                  <TableCell>{title}</TableCell>
                  <TableCell>{listing_type}</TableCell>
                  <TableCell itemType="number">
                    {integration_job
                      ? `${(event_job_aggregate?.aggregate.count ?? 0)} (clicks)`
                      : applications_aggregate?.aggregate.count}
                  </TableCell>
                  <TableCell itemType="number">{job_views.views}</TableCell>
                  <TableCell itemType="date">{list_date}</TableCell>
                  <TableCell itemType="date">{expiry_date}</TableCell>
                  <TableCell>{integration_job ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

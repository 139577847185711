import { ResourceProps, ShowGuesser } from 'react-admin';
import { UserList } from 'resources/user/UserList';

export const user: ResourceProps = {
  name: 'user',
  list: UserList,
  show: ShowGuesser,
  // edit: EditGuesser,
  // create: EditGuesser,
};

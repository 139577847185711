import { FC } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { ButtonRevalidate } from 'components';

const postFilters = [<TextInput key="search" source="name" alwaysOn />];

export const EmployerList: FC<ListProps> = (props) => (
  <List {...props} filters={postFilters}>
    <Datagrid>
      <FunctionField
        label="Name"
        render={(record: any) => (
          <Link to={`/employer/${record.id}/show`}>{record.name}</Link>
        )}
      />
      <TextField source="category" />
      <TextField source="billing_type" label="Billing" />
      {/* <TextField source="cover_photo" /> */}
      {/* <TextField source="description" /> */}
      <ReferenceField
        source="location_id"
        reference="location"
        label="Region"
        link="show"
      >
        <TextField source="region" />
      </ReferenceField>
      <TextField source="organisation_type" label="Type" />
      <TextField source="main_contact" />
      <ReferenceField
        source="parent_id"
        reference="employer"
        label="Parent"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      {/* <TextField source="id" /> */}
      {/* <TextField source="logo" /> */}
      <BooleanField source="page_active" />
      <TextField source="slug" />
      {/* <TextField source="facebook" />
      <TextField source="instagram" />
      <TextField source="linkedin" /> */}
      {/* <TextField source="testimonial" /> */}
      {/* <TextField source="testimonial_from" /> */}
      <TextField source="website" />
      <DateField source="created_at" />
      <FunctionField
        label="Refresh"
        render={(record: any) => (
          <ButtonRevalidate path={`/directory/${record.slug}`} />
        )}
      />
    </Datagrid>
  </List>
);

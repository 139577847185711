import { ApolloQueryResult, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getDBClient } from 'utils';

type Enum = { label: string; value: string };

type EnumTable = Enum[];

type EnumData = {
  enum_access_level: EnumTable;
  enum_application_status: EnumTable;
  enum_billing: EnumTable;
  enum_category: EnumTable;
  enum_job_status: EnumTable;
  enum_listing: EnumTable;
  enum_organisation: EnumTable;
  enum_pay_type: EnumTable;
  enum_question_type: EnumTable;
  enum_work_type: EnumTable;
};

const query = gql`
  query MyQuery {
    enum_access_level(order_by: { label: asc }) {
      label
      value
    }
    enum_billing(order_by: { label: asc }) {
      label
      value
    }
    enum_category(order_by: { label: asc }) {
      label
      value
    }
    enum_job_status(order_by: { label: asc }) {
      label
      value
    }
    enum_pay_type(order_by: { label: asc }) {
      label
      value
    }
    enum_question_type(order_by: { label: asc }) {
      label
      value
    }
    enum_work_type(order_by: { label: asc }) {
      label
      value
    }
    enum_listing(order_by: { label: asc }) {
      label
      value
    }
    enum_organisation(order_by: { label: asc }) {
      label
      value
    }
  }
`;

const defaultData: EnumData = {
  enum_access_level: [],
  enum_application_status: [],
  enum_category: [],
  enum_job_status: [],
  enum_pay_type: [],
  enum_question_type: [],
  enum_work_type: [],
  enum_billing: [],
  enum_listing: [],
  enum_organisation: [],
};

export const useEnums = (): ApolloQueryResult<EnumData> => {
  const [response, setResponse] = useState<ApolloQueryResult<EnumData>>({
    data: defaultData,
    loading: false,
    networkStatus: NaN,
  });

  useEffect(() => {
    (async (): Promise<void> => {
      const res = await (await getDBClient()).query<EnumData>({ query });
      setResponse(res);
    })();
  }, []);

  return response;
};

type EnumChoice = {
  id: string;
  name: string;
};

export const makeChoices = (enums: EnumTable): EnumChoice[] =>
  enums.map(({ value, label }) => ({
    id: value,
    name: label,
  }));

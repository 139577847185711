import React, { Suspense } from 'react';
import { gql, useQuery } from '@apollo/client';

/* eslint-disable import/no-duplicates */
import set from 'date-fns/set';
import enNZ from 'date-fns/locale/en-NZ';
import setDefaultOptions from 'date-fns/setDefaultOptions';
/* eslint-enable import/no-duplicates */

// Suspend loading any items from @tremor/react
const AreaChart = React.lazy(() => import('components/AreaChart'));
const ReportCard = React.lazy(() => import('components/ReportCard'));
const Card = React.lazy(() => import('components/tremor/Card'));
const Col = React.lazy(() => import('components/tremor/Col'));
const Grid = React.lazy(() => import('components/tremor/Grid'));
const Title = React.lazy(() => import('components/tremor/Title'));

const toPercent = (a: number, t: number): number =>
  Number(((a / t) * 100).toFixed(1)) * 1;

setDefaultOptions({ locale: enNZ });

const REPORTING = gql`
  query ReportingSubscription($date: date!, $timestamp: timestamptz!) {
    job_aggregate(
      where: { list_date: { _gte: $date }, expiry_date: { _is_null: false } }
    ) {
      aggregate {
        count
      }
    }
    integration_jobs: job_aggregate(
      where: {
        list_date: { _gte: $date }
        expiry_date: { _is_null: false }
        integration_job: { id: { _is_null: false } }
      }
    ) {
      aggregate {
        count
      }
    }
    employers: user_aggregate(
      where: { role: { _eq: employer }, created_at: { _gte: $timestamp } }
    ) {
      aggregate {
        count
      }
    }
    tradies: user_aggregate(
      where: { role: { _eq: employee }, created_at: { _gte: $timestamp } }
    ) {
      aggregate {
        count
      }
    }
    applications: application_aggregate(
      where: {
        created_at: { _gte: $timestamp }
        status: { _in: [applied, withdrawn, shortlisted, rejected, hired] }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const thisMonth = set(new Date(), {
  date: 1,
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});

const jobTarget = 300;
const employerTarget = 40;
const applicationsTarget = 300;
const tradiesTarget = 500;

export default function ReportDashboard(): JSX.Element | null {
  const { loading, data, error } = useQuery(REPORTING, {
    variables: {
      date: thisMonth.toISOString(),
      timestamp: thisMonth.toISOString(),
    },
    pollInterval: 10000,
  });
  console.log('error::', error);
  if (loading || !data) return null;
  const newJobs = data.job_aggregate.aggregate.count;
  const newEmployers = data.employers.aggregate.count;
  const newApplications = data.applications.aggregate.count;
  const newTradies = data.tradies.aggregate.count;
  const newIntegrationJobs = data.integration_jobs.aggregate.count;

  return (
    <div className="dashboard-main">
      <Suspense>
        <div className="mt-10">
          <Title>{new Date().toDateString()}</Title>
        </div>
        <Grid numItems={1} numItemsMd={2} numItemsLg={4} className='gap-2'>
          <Col numColSpan={1}>
          <ReportCard
            unit={newJobs}
            target={jobTarget}
            title="Jobs"
            color="indigo"
            tooltip={`${toPercent(
              newIntegrationJobs,
              newJobs
            )}% from integration`}
          />
          </Col>
          <Col numColSpan={1}>
          <ReportCard
            unit={newEmployers}
            target={employerTarget}
            title="Employers"
            color="amber"
          />

          </Col>
          <Col numColSpan={1}>
          <ReportCard
            unit={newTradies}
            target={tradiesTarget}
            title="Tradies"
            color="red"
          />
            
          </Col>
          <Col numColSpan={1}>
          <ReportCard
            unit={newApplications}
            target={applicationsTarget}
            title="Applications"
            color="green"
          />
          </Col>
        </Grid>

        <div className="mt-6">
          <Card>
            <AreaChart />
          </Card>
        </div>
      </Suspense>
    </div>
  );
}

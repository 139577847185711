import { FC } from 'react';
import {
  // BooleanField,
  Datagrid,
  DateField,
  // SelectInput,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  TextInput,
  TextField,
  AutocompleteInput,
} from 'react-admin';

const postFilters = [
  <TextInput key="search" source="title" alwaysOn />,
  <ReferenceInput
    alwaysOn
    source="employer_id"
    reference="employer"
    key="employer"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={Infinity}
  >
    <AutocompleteInput
      filterToQuery={name => ({ name })}
      optionText="name"
      emptyText="All"
    />
  </ReferenceInput>,
];

export const JobList: FC<ListProps> = (props) => (
  <List {...props} filters={postFilters}>
    <Datagrid rowClick="show">
      {/* <TextField source="about" /> */}
      {/* <TextField source="id" /> */}
      <DateField source="created_at" />
      <DateField source="list_date" />
      <DateField source="expiry_date" />
      <TextField source="title" />
      <ReferenceField
        source="employer_id"
        reference="employer"
        link="show"
        sortBy="employer.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="location_id"
        reference="location"
        label="Region"
        sortBy="location.region"
        link="show"
      >
        <TextField source="region" />
      </ReferenceField>
      {/* <ReferenceField
          source="location_id"
          reference="location"
          sortBy="location.municipality"
          label="City"
          link="show"
        >
          <TextField source="municipality" />
        </ReferenceField> */}
      {/* <TextField source="highlights" /> */}
      {/* <TextField source="job_category" /> */}
      <TextField source="job_status" label="Status" />
      <TextField source="listing_type" label="Type" />
      {/* <NumberField source="pay_high" /> */}
      {/* <NumberField source="pay_low" /> */}
      {/* <TextField source="pay_type" /> */}
      {/* <BooleanField source="show_pay" /> */}
      {/* <TextField source="slug" /> */}
      {/* <TextField source="tagline" /> */}
      {/* <TextField source="work_type" /> */}
      {/* <DateField source="updated_at" /> */}
      <TextField label="Views" source="job_views.views" />
      <TextField
        label="Applied"
        sortable={false}
        source="applications_aggregate.aggregate.count"
      />
      <TextField
        label="Watched"
        sortable={false}
        source="watchlists_aggregate.aggregate.count"
      />
    </Datagrid>
  </List>
);

import { buildFields } from 'ra-data-hasura';
import gql from 'graphql-tag';

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) => {
  return queryAst.definitions[0].selectionSet.selections;
};

// Define the additional fields that we want.
const EXTEND_JOBS = gql`
  {
    job_views {
      views
    }
    watchlists_aggregate(where: { deleted_at: { _is_null: true } }) {
      aggregate {
        count
      }
    }
    applications_aggregate(
      where: { status: { _in: [applied, shortlisted, hired, rejected] } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const customBuildFields = (type: any, fetchType: any): any => {
  const resourceName = type.name;

  // First take the default fields (all, but no related or nested).
  const defaultFields = buildFields(type, fetchType);

  if (resourceName === 'job') {
    const relatedEntities = extractFieldsFromQuery(EXTEND_JOBS);
    defaultFields.push(...relatedEntities);
  }

  // Extend other queries for other resources/fetchTypes here...

  return defaultFields;
};

import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';
import { v4 as uuidV4 } from 'uuid';
import { EmployerFormBody } from './EmployerFormBody';

export const EmployerCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <EmployerFormBody initialValues={{ id: uuidV4() }} />
  </Create>
);

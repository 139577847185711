import {
  Show,
  SimpleShowLayout,
  TextField,
  ShowProps,
  EditButton,
  ReferenceField,
  TopToolbar,
  FunctionField,
} from 'react-admin';

export const EmployerUserShow = (props: ShowProps): JSX.Element => {
  return (
    <Show
      {...props}
      actions={
        <FunctionField
          render={(props: any) => <EmployerUserShowActions {...props} />}
        />
      }
    >
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="user_id" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="employer_id" reference="employer">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="access_id" />
      </SimpleShowLayout>
    </Show>
  );
};
const EmployerUserShowActions = (record: any): JSX.Element => {
  return (
    <TopToolbar>
      <EditButton record={record} />
    </TopToolbar>
  );
};

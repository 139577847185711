import { useEffect, useState, FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { getDBClient } from 'utils';
import { AdminRouter } from 'pages';

const ReactAdmin: FC = () => {
  const [client, setClient] = useState<ApolloClient<Record<string, unknown>>>();

  useEffect(() => {
    const buildDataProvider = async () => {
      const cli = await getDBClient();
      setClient(cli);
    };
    buildDataProvider();
  }, []);

  if (!client) return <p>Loading...</p>;

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<AdminRouter />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default ReactAdmin;

import {
  AutocompleteInput,
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';

const postFilters = [
  <ReferenceInput
    alwaysOn
    source="employer_id"
    reference="employer"
    key="employer"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={Infinity}
  >
    <AutocompleteInput
    filterToQuery={name => ({ name })}
      optionText="name"
      emptyText="All"
    />
  </ReferenceInput>,
  <ReferenceInput
    alwaysOn
    source="user_id"
    reference="user"
    key="user"
    sort={{ field: 'email', order: 'ASC' }}
    filter={{ role: 'employer' }}
    perPage={Infinity}
  >
    <AutocompleteInput
      filterToQuery={email => ({ email })}
      optionText="email"
      emptyText="All"
    />
  </ReferenceInput>,
];

export function EmployerUserList(props: ListProps): JSX.Element {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid rowClick="show">
        <ReferenceField
          source="user_id"
          reference="user"
          label="User"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="employer_id"
          reference="employer"
          label="Employer"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="access_id" />
      </Datagrid>
    </List>
  );
}

import { Auth } from '@aws-amplify/auth';
import jwtDecode from 'jwt-decode';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { WithChildren } from 'utils/types';
import { Login } from 'components/Login';

type User = {
  email: string;
  family_name: string;
  given_name: string;
  sub: string;
};

type AuthContextResult = {
  authenticated: boolean;
  user?: User | null;
  signout: () => void;
};

const setUserFromToken = (jwtToken: string): User => {
  const { email, family_name, given_name, sub } = jwtDecode(jwtToken) as User;
  return { email, family_name, given_name, sub };
};

const AuthContext = createContext<AuthContextResult>({
  authenticated: false,
  signout: () => false,
});

export const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    if (user) return;
    (async () => {
      try {
        const session = await Auth.currentSession();
        const user = setUserFromToken((session as any).idToken.jwtToken);
        setUser(user);
      } catch {
        setUser(null);
      }
    })();
  });

  const signout = useCallback((a?: User) => setUser(a), [setUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: !!user,
        signout,
      }}
    >
      {!user ? <Login /> : children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextResult => useContext(AuthContext);

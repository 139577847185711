import { ResourceProps } from 'react-admin';
import { EmployerUserCreate } from './EmployerUserCreate';
import { EmployerUserList } from './EmployerUserList';
import { EmployerUserEdit } from './EmployerUserEdit';
import { EmployerUserShow } from './EmployerUserShow';

export const employer_user: ResourceProps = {
  name: 'employer_user',
  list: EmployerUserList,
  show: EmployerUserShow,
  edit: EmployerUserEdit,
  create: EmployerUserCreate,
};

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';

const link = createHttpLink({ uri: process.env.REACT_APP_HASURA_API_URL });

export const getDBClient = async (): Promise<
  ApolloClient<Record<string, unknown>>
> => {
  const session = await Auth.currentSession(); // happens on the client
  const jwtToken = session.getIdToken().getJwtToken();

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    };
  });

  return new ApolloClient({
    // link: authLink.concat(link),
    link: from([authLink, link]),
    cache: new InMemoryCache(),
  });
};

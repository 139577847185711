import {
  AppBar as MuiAppBar,
  Button,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDrawerContext } from 'components/DrawerNavigation';

export default function AppBar(): JSX.Element {
  const { open, toggle } = useDrawerContext();
  return (
    <MuiAppBar>
      <Toolbar>
        <Button style={{ color: '#FFF' }} onClick={() => toggle(!open)}>
          <MenuIcon />
        </Button>
        <Typography variant="h6" color="inherit" id="react-admin-title" />
      </Toolbar>
    </MuiAppBar>
  );
}

import { Auth } from '@aws-amplify/auth';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { Maybe } from 'graphql/jsutils/Maybe';
import axios from 'axios';

export const EXPORT_QUERY = gql`
  query GetJobSummaryForEmployerByDate(
    $employer_id: uuid!
    $start_date: date
    $end_date: date
  ) {
    job(
      where: {
        employer_id: { _eq: $employer_id }
        list_date: { _gte: $start_date, _lte: $end_date }
        expiry_date: { _is_null: false }
      }
    ) {
      id
      title
      list_date
      expiry_date
      listing_type
      integration_job {
        id
      }
      event_job_aggregate(where: {event: {_eq: "job_apply_external"}}) {
      aggregate {
        count
       }
      }
      applications_aggregate(
        where: { status: { _in: [applied, withdrawn, shortlisted, rejected] } }
      ) {
        aggregate {
          count
        }
      }
      job_views {
        views
      }
    }
  }
`;

export const GET_EMPLOYERS_QUERY = gql`
  query {
    employer(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export type EmployerSelectType = {
  id: string;
  name: string;
};

export const GET_EMPLOYER_NAME = gql`
  query GetEmployerById($id: uuid!) {
    employer_by_pk(id: $id) {
      name
    }
  }
`;

export const headers = [
  'Job Title',
  'Listing Type',
  '# Applications',
  '# Views',
  'List Date',
  'End Date',
  'Integration',
];

export type JobType = {
  id: string;
  title: string;
  list_date: string;
  expiry_date: string;
  listing_type: 'featured' | 'standard';
  event_job_aggregate?: {
    aggregate: {
      count: number;
    };
  };
  applications_aggregate: {
    aggregate: {
      count: number;
    };
  };
  job_views: {
    views: number;
  };
  integration_job?: Maybe<{
    id: string;
  }>;
};

export async function createReport(
  employer_id: string,
  start_date: string,
  end_date: string
) {
  try {
    const session = await Auth.currentSession();

    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_DEV_WEBSITE}/api/reports/${employer_id}/xlsx?start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
        },
        responseType: 'blob',
      }
    );
    let filename = '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headers['content-disposition']);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.log('err:', err);
  }
}

import { Divider } from '@mui/material';
import { FC } from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EditButton,
  Link,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowActionsProps,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { WithChildren } from 'utils/types';
import { revalidatePath } from 'utils';

export const JobShow: FC<ShowProps> = (props) => {
  console.log('PROPS::', props)
  return (
    <Show {...props} actions={<JobShowActions />}>
      <SimpleShowLayout>
        <h4>Required fields</h4>
        <TextField source="title" />
        <RichTextField source="about" />
        <ReferenceField source="employer_id" reference="employer" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="location_id" reference="location" link="show">
          <TextField source="label" />
        </ReferenceField>
        <TextField source="job_category" />
        <TextField source="listing_type" />
        <TextField source="work_type" />
        <TextField source="pay_type" />
        <NumberField source="pay_low" />
        <NumberField source="pay_high" />
        <BooleanField source="show_pay" />
        <TextField source="slug" />
        <DateField source="created_at" />
        <Divider />
        <h4>Required to make listing live</h4>
        <TextField source="job_status" />
        <DateField source="list_date" />
        <DateField source="expiry_date" />
        <small>Must not be set for listing to be live</small>
        <DateField source="deleted_at" />
        <Divider />
        <h4>Optional fields</h4>
        <TextField source="apply_url" />
        <TextField source="tagline" />
        <TextField source="highlights" />
        <TextField source="requirements" />
        <h4>Relational fields</h4>
        <ReferenceManyField
          label="Additional Categories"
          reference="job_additional_category"
          target="job_id"
          sort={{ field: 'category', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="category" label="Category" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddRelativeButton relative="job_additional_category">
          Add a Category
        </AddRelativeButton>
        <Divider />
        <ReferenceManyField
          label="Job Questions"
          reference="job_question"
          target="job_id"
          sort={{ field: 'order', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source="question" label="Job Questions" />
            <NumberField source="order" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddRelativeButton relative="job_question">
          Add a Question
        </AddRelativeButton>
      </SimpleShowLayout>
    </Show>
  );
};

type AddProps = WithChildren<{
  relative: 'job_question' | 'job_additional_category';
}>;

const AddRelativeButton: FC<AddProps> = ({ children, relative }) => {
  const record = useRecordContext();

  return <Link to={`/${relative}/create?job_id=${record.id}`}>{children}</Link>;
};

const JobShowActions: FC<ShowActionsProps> = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Button
        label="Revalidate Job"
        onClick={() => {
          revalidatePath(`/job/${record?.slug}`);
          alert('Revalidate job successful');
        }}
      />
      <EditButton record={record} />
      {/* Add your custom actions */}
      <Button
        color="primary"
        label="View job on site"
        onClick={() => {
          const baseUrl =
            process.env.REACT_APP_DEV_WEBSITE ?? 'https://tradejobsnz.co.nz';
          record?.job_status === 'active' ?
            window.open(`${baseUrl}/job/${record?.slug}`, '_blank') : window.open(`${baseUrl}/job/${record?.slug}/draft`, '_blank');
        }}
      />
    </TopToolbar>
  )
};

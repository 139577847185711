import {
  Card,
  Col,
  Color,
  Flex,
  Icon,
  Metric,
  ProgressBar,
  Text,
} from '@tremor/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StatusBadge from './StatusBadge';

const toPercent = (a: number, t: number): number =>
  Number(((a / t) * 100).toFixed(1)) * 1;

type ReportCardProps = {
  color: Color;
  title: string;
  unit?: number;
  target: number;
  tooltip?: string | null;
};

export default function ReportCard({
  color,
  target,
  title,
  tooltip,
  unit = 0,
}: ReportCardProps): JSX.Element {
  const percent = toPercent(unit, target);

  return (
    <Card>
      <Flex alignItems="start">
        <Col>
          <Text>{title}</Text>
          <Metric>
            {unit}
            {tooltip && (
              <Icon
                size="xs"
                icon={InfoOutlinedIcon}
                tooltip={tooltip as any}
              />
            )}
          </Metric>
        </Col>
        <StatusBadge target={percent} />
      </Flex>
      <Flex className="mt-4">
        <Text>{percent}% of monthly target</Text>
        <Text>{target}</Text>
      </Flex>
      <ProgressBar color={color} value={percent} className="mt-2" />
    </Card>
  );
}

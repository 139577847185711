import { Auth } from '@aws-amplify/auth';
import { TextField } from 'mui-rff';
import { useState, FC } from 'react';
import { Form } from 'react-final-form';

import Logo from './Logo.svg';
import './styles.css';

export const Login: FC = () => {
  const [error, setError] = useState<string | undefined>();

  async function onSubmit({ username, password }: any) {
    try {
      await Auth.signIn(username, password);
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) setError(error?.message);
    }
  }
  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={Logo} alt="logo" />
        <p className="text-right">
          <small>
            <b>ADMIN PORTAL</b>
          </small>
        </p>
      </div>
      <Form
        onSubmit={onSubmit}
        subscription={{ values: true, submitting: true, pristine: true }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="login-input">
              <TextField type="text" label="Username" name="username" />
            </div>
            <div className="login-input">
              <TextField type="password" label="Password" name="password" />
            </div>
            <button className="login-submit" type="submit">
              Submit
            </button>
            {error}
          </form>
        )}
      />
    </div>
  );
};

import { FC } from 'react';
import * as Yup from 'yup';
import {
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
} from 'react-admin';
import { makeChoices, makeValidateDebug, useEnums } from 'utils';
import { JobQuestionFormToolbar } from 'resources/job_question';

type Props = {
  initialValues?: Record<string, unknown>;
};

export const JobAdditionalCategoryFormBody: FC<Props> = ({
  initialValues = {},
}) => {
  const {
    data: { enum_category },
  } = useEnums();
  return (
    <SimpleForm
      {...{ validate, initialValues }}
      toolbar={<JobQuestionFormToolbar />}
    >
      <ReferenceField source="job_id" reference="job" fullWidth link="show">
        <TextField
          style={{ fontWeight: 'bold', fontSize: '1.4rem' }}
          source="title"
        />
      </ReferenceField>
      <SelectInput source="category" choices={makeChoices(enum_category)} />
    </SimpleForm>
  );
};

const schema: Yup.AnySchema = Yup.object({
  job_id: Yup.string().required(),
  category: Yup.string().required(),
});

const validate = makeValidateDebug(schema);

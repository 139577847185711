import { useQuery } from '@apollo/client';
import { AreaChart as AreaChartDefault, Title } from '@tremor/react';
import { useEffect, useState } from 'react';
import { Loading } from 'react-admin';

import { formatQuery, formatResult, ChartData } from './utils';

const CHART_QUERY = formatQuery();

export default function AreaChart() {
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const { loading, data } = useQuery(CHART_QUERY, {
    fetchPolicy: 'no-cache',
    // ten minutes
    pollInterval: 60 * 10 * 1000,
  });

  useEffect(() => {
    if (loading || !data) return;
    const formattedData = formatResult(data);
    setChartData(formattedData);
  }, [loading, data]);

  if (loading) return <Loading />;

  return (
    <>
      <Title>Monthly Results</Title>
      <AreaChartDefault
        className='h-96 mt-6'
        index="dateString"
        data={chartData}
        // dataKey="dateString"
        categories={['Jobs', 'Employers', 'Tradies', 'Applications']}
        colors={['indigo', 'amber', 'red', 'green']}
      />
    </>
  );
}

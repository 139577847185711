import { gql } from '@apollo/client';
/* eslint-disable import/no-duplicates */
import { add, format, set } from 'date-fns';
import enNZ from 'date-fns/locale/en-NZ';
import setDefaultOptions from 'date-fns/setDefaultOptions';
/* eslint-enable import/no-duplicates */

setDefaultOptions({ locale: enNZ });

function createQuery(date: Date): string {
  const startDate = set(new Date(date), midnight);
  const endDate = set(add(date, { months: 1 }), midnight);
  const sdPre = format(startDate, 'yyyy_MM_dd');
  const dateFrag = `{
    _gte: "${startDate.toISOString()}"
    _lt: "${endDate.toISOString()}"
  }`;
  return `
    Jobs_${sdPre}: job_aggregate(
      where: {
        list_date: ${dateFrag}
        expiry_date: { _is_null: false }
      }
    ) {
      aggregate {
        count
      }
    }
    Employers_${sdPre}: user_aggregate(
      where: {
        created_at: ${dateFrag}
        role: { _eq: employer }
      }
    ) {
      aggregate {
        count
      }
    }
    Tradies_${sdPre}: user_aggregate(
      where: {
        created_at: ${dateFrag}
        role: { _eq: employee }
      }
    ) {
      aggregate {
        count
      }
    }
    Applications_${sdPre}: application_aggregate(
      where: {
        created_at: ${dateFrag}
        status: { _in: [applied, withdrawn, rejected, hired, shortlisted] }
      }
    ) {
      aggregate {
        count
      }
    }
  `;
}

const midnight = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

const startDate = set(new Date('2021-08-01'), midnight);
const endDate = set(add(new Date(), { months: 1 }), { date: 1, ...midnight });

export function formatQuery() {
  let currentDate = startDate;
  const monthQueries = [];
  do {
    monthQueries.push(createQuery(currentDate));
    currentDate = add(currentDate, { months: 1 });
  } while (currentDate < endDate);

  return gql`
    query ComplexReportQuery {
      ${monthQueries.join('\n')}
    }
  `;
}

type QueryResult = Record<string, { aggregate: { count: number } }>;

export type ChartData = {
  applications: number;
  date: string;
  dateString: string;
  employers: number;
  jobs: number;
  tradies: number;
};

const defaultData: ChartData = {
  applications: 0,
  date: '',
  dateString: '',
  employers: 0,
  jobs: 0,
  tradies: 0,
};

export function formatResult(q: QueryResult): ChartData[] {
  const res: ChartData[] = [];
  Object.keys(q).forEach((k) => {
    const [l, year, month, day] = k.split('_');
    const date = `${year}-${month}-${day}`;
    let i = res.findIndex((a) => a.date === date);
    if (i < 0) i = res.length;
    res[i] = {
      ...(i < 0 ? defaultData : res[i]),
      date,
      dateString: format(new Date(date), 'MMM yy'),
      [l]: q[k].aggregate.count,
    };
  });
  return res;
}

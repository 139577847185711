import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import * as Yup from 'yup';
import { makeChoices, makeValidateDebug, useEnums } from 'utils';

type Props = {
  initialValues?: Record<string, unknown>;
};

export function EmployerUserFormBody({
  initialValues = {},
}: Props): JSX.Element {
  const {
    data: { enum_access_level },
  } = useEnums();
  const today = new Date();
  const nextMonth = new Date();
  nextMonth.setDate(today.getDate() + 30);

  return (
    <SimpleForm {...{ validate, initialValues }}>
      <ReferenceInput
        required
        source="employer_id"
        reference="employer"
        perPage={Infinity}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="name"
          fullWidth
          filterToQuery={name => ({ name })}
        />
      </ReferenceInput>
      <ReferenceInput
        required
        source="user_id"
        reference="user"
        perPage={Infinity}
        sort={{ field: 'email', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="email"
          fullWidth
          filterToQuery={email => ({ email })}
        />
      </ReferenceInput>

      <SelectInput
        required
        source="access_id"
        choices={makeChoices(enum_access_level)}
      />
    </SimpleForm>
  );
}

const schema: Yup.AnySchema = Yup.object({
  employer_id: Yup.string().required(),
  user_id: Yup.string().required(),
  access_id: Yup.string().required(),
});

const validate = makeValidateDebug(schema, true);

import { FC } from 'react';
import {
  DateInput,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import * as Yup from 'yup';
import { JobQuestionFormToolbar } from 'resources/job_question/JobQuestionCreate';
import { makeChoices, makeValidateDebug, useEnums } from 'utils';

type Props = {
  initialValues?: Record<string, unknown>;
};

export const JobQuestionFormBody: FC<Props> = ({ initialValues = {} }) => {
  const {
    data: { enum_question_type },
  } = useEnums();

  return (
    <SimpleForm
      {...{ validate, initialValues }}
      toolbar={<JobQuestionFormToolbar />}
    >
      <ReferenceField source="job_id" reference="job" fullWidth link="show">
        <TextField
          source="title"
          style={{ fontWeight: 'bold', fontSize: '1.4rem' }}
        />
      </ReferenceField>
      <TextInput source="question" fullWidth multiline />
      <SelectInput source="type" choices={makeChoices(enum_question_type)} />
      <NumberInput
        source="order"
        label="Order at which it appears in the application form"
        fullWidth
      />
      <DateInput source="created_at" />
    </SimpleForm>
  );
};

const schema: Yup.AnySchema = Yup.object({
  job_id: Yup.string().required(),
  question: Yup.string().required(),
  type: Yup.string().required(),
  order: Yup.number().min(0),
});

const validate = makeValidateDebug(schema);
